export const environment = {
  production: true,
  jwtName: 'jwt',
  domainLogin: 'https://mobile.rpa.accpt.udata.ca',
  domainCookie: 'mobile.rpa.accpt.udata.ca',
  tracing: false,
  clientId: 'mobileui',
  issuer: 'https://intg.rpa360.com/udata.identity.api',
  skipIssuerCheck: false
};
